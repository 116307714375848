import React from 'react'
import Draggable from 'react-draggable';
import { useEffect, useState } from 'react';


export default function Pipeline() {


  const [blocks,setblocks] = useState(["one","two","three","four","five","six","seven","eight",])
    const [mousehover,setmousehover] = useState(false)
    const [mousehovevalue,setmousehovervalue] = useState("")

    const blockMouseHover = (event) => {
        console.log(event.target.id)
        setmousehover(true)
    }

    const blockMouseOut = (event) => {
        setmousehover(false)
    }

    useEffect(() => {

    },[mousehover])

  return (
    <div className="all_user_container">
        
        <div className="Sidebar_new">
        <div className="sidebar_header">
            <h3>All Blocks</h3>
        </div>
        <ul className="pipelinelist">
          {blocks.map((bloc,index) => {
                        return (
                            <Draggable key={index}>
                            <div  key={index} className="movable">
                            <div onMouseOver ={(event) => blockMouseHover(event) } onMouseOut={(event) => blockMouseOut(event)} id={index} className="sidebar_body">
                                {bloc}
                            </div>
                            </div>
                            </Draggable>
                        )
                    })}
        </ul>
        </div>

        <div className="container-fluid">
            <div className="pipeline-body">

            </div>
        </div>
    </div>
  )
}
