import { Fragment, useState, useMemo } from "react"
import { JsonForms } from "@jsonforms/react"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import axios from 'axios';

import { materialCells, materialRenderers } from "@jsonforms/material-renderers"
//import RatingControl from './RatingControl';
//import ratingControlTester from './ratingControlTester';
import { makeStyles } from "@mui/styles"
import { useNavigate } from "react-router-dom"

const useStyles = makeStyles({
  container: {
    padding: "1em",
    width: "100%"
  },
  title: {
    textAlign: "center",
    padding: "0.25em"
  },
  dataContent: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "0.25em",
    backgroundColor: "#cecece",
    marginBottom: "1rem"
  },
  resetButton: {
    margin: "auto !important",
    display: "block !important"
  },
  demoform: {
    margin: "auto",
    padding: "1rem"
  }
})

const schema = {
  definitions: {
    slotvalidationdata: {
      title: "Validation Data",
      type: "array",
      items: {
        type: "string"
      }
    },
    slotapidata: {
      title: "Sample Data coming from API",
      type: "object",
      properties: {
        dataApiUrl: {
          type: "string"
        },
        dataMethod: {
          type: "string",
          enum: ["GET", "POST"]
        },
        dataApiParameters: {
          type: "array",
          items: {
            type: "string"
          }
        },
        dataResponseParameters: {
          type: "array",
          items: {
            type: "string"
          }
        }
      }
    },
    conditionalclosing: {
      title: "Conditional Closing",
      type: "array",
      items: {
        type: "string"
      }
    },
    conditionalskipping: {
      title: "Conditional Skipping",
      type: "array",
      items: {
        type: "object",
        properties: {
          attributename: {
            type: "string"
          },
          attributevalue: {
            type: "string"
          }
        }
      }
    },
    showmessage: {
      title: "Action Message",
      type: "string"
    },
    actionapidata: {
      title: "Action Calling API",
      type: "object",
      properties: {
        actionApiUrl: {
          type: "string"
        },
        actionApiMethod: {
          type: "string",
          enum: ["GET", "POST"]
        },
        actionApiParameters: {
          type: "array",
          items: {
            type: "string"
          }
        },
        actionResponseParameters: {
          type: "array",
          items: {
            type: "string"
          }
        }
      }
    }
  },
  type: "object",
  properties: {
    Goal: {
      title: "Goal",
      type: "string",
      minLength: 1
    },
    Intent: {
      title: "Intent",
      type: "string"
    },
    Language: {
      title: "Language",
      type: "string",
      enum: [
        "English",
        "French",
        "Tunisian Arabic",
        "Tunisian Roman",
        "Bengali"
      ]
    },
    SubIntent: {
      title: "Sub Intent",
      type: "string"
    },
    Questions: {
      title: "Questions",
      type: "array",
      items: {
        title: "Question",
        type: "string"
      }
    },
    Slots: {
      title: "Slots",
      type: "array",
      items: {
        type: "object",
        properties: {
          slotname: {
            title: "Slot Name",
            type: "string",
            minLength: 1
          },
          datatype: {
            title: "Data Type",
            type: "string",
            enum: ["string", "number", "boolean","dropbox","carousel","checkbox"]
          },
          datavalidation: {
            title: "Data Validation",
            type: "string",
            enum: ["true", "false"]
          },
          entitytype: {
            title: "Entity Type",
            type: "string",
            enum: [
              "Name",
              "Email",
              "Date",
              "Number",
              "Unit",
              "Location",
              "City",
              "Regex",
              "Rule",
              "Custom"
            ]
          },
          exhaustive: {
            title: "Exhaustive",
            type: "string",
            enum: ["true", "false"]
          },
          imageurl: {
            title: "Image URL",
            type: "string"
          },
          slotquestion: {
            title: "Question",
            type: "string"
          },
          slotvalidation: {
            title: "Slot Validation",
            type: "object",
            properties: {
              dataorapi: {
                oneOf: [
                  {
                    "title": "Validation Data",
                    $ref: "#/definitions/slotvalidationdata"
                  },
                  {
                    "title": "SAMPLE DATA COMING FROM API",
                    $ref: "#/definitions/slotapidata"
                  }
                ]
              }
            }
          },
          slotaction: {
            title: "Slot Validation Action",
            type: "object",
            properties: {
              closeorskip: {
                anyOf: [
                  {
                    "title": "Condition Closing",
                    $ref: "#/definitions/conditionalclosing"
                  },
                  {
                    "title": "Condition Skipping",
                    $ref: "#/definitions/conditionalskipping"
                  }
                ]
              }
            }
          }
        }
      }
    },
    Action: {
      title: "Action",
      type: "object",
      properties: {
        messageorapi: {
          oneOf: [
            {
              "title": "Action Message",
              $ref: "#/definitions/showmessage"
            },
            {
              "title": "Action Calling Api",
              $ref: "#/definitions/actionapidata"
            }
          ]
        }
      }
    }
  },
  required: ["Goal", "Intent"]
}

const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/Goal"
        },
        {
          type: "Control",
          scope: "#/properties/Intent"
        },
        {
          type: "Control",
          scope: "#/properties/Language"
        }
      ]
    },
    {
      type: "Control",
      scope: "#/properties/SubIntent"
    },
    {
      type: "Control",
      scope: "#/properties/Questions"
    },
    {
      type: "ListWithDetail",
      scope: "#/properties/Slots",
      options: {
        detail: {
          type: "VerticalLayout",
          elements: [
            {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/slotname"
                },
                {
                  type: "Control",
                  scope: "#/properties/datatype"
                },
                {
                  type: "Control",
                  scope: "#/properties/datavalidation"
                }
              ]
            },
            {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/entitytype"
                },
                {
                  type: "Control",
                  scope: "#/properties/exhaustive"
                },
                {
                  type: "Control",
                  scope: "#/properties/imageurl"
                }
              ]
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/slotquestion"
                },
                {
                  type: "Control",
                  scope: "#/properties/slotvalidation"
                },
                {
                  type: "Control",
                  scope: "#/properties/slotaction"
                }
              ]
            }
          ]
        }
      }
    },
    {
      type: "Control",
      scope: "#/properties/Action"
    }
  ]
}
const initialData = {
  Goal: "New Goal",
  Intent: "New Intent",
  Language: "English"
}

const renderers = [
  ...materialRenderers
  //register custom renderers
  //  { tester: ratingControlTester, renderer: RatingControl },
]

const ConversationBot = () => {
  const classes = useStyles()
  const [data, setData] = useState(initialData)
  const stringifiedData = useMemo(() => JSON.stringify(data, null, 2), [data])

  const navigate = useNavigate();
  const clearData = () => {
    setData({})
  }

  const saveData = () => {
    console.log("Submitted", data)
    const client_id= localStorage.getItem("client_id")
    let dataUpdated={
      ...data.Slots
    }
    console.log("dataUpdated",dataUpdated)
    callConfigureApi(data,client_id);
    navigate("/trainYourBot")
  }



  const callConfigureApi = async (data,client_id) => {
    console.log("configure api called");
   
    const url = `https://bot.assisto.tech/configdata?client_id=${client_id}`;

    try {
      console.log("url",url)
      const response = await axios.post(url, data);
      console.log("response message", response.data);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <Fragment>
                <Grid
                  container
                  justifyContent={"center"}
                  spacing={1}
                  className={classes.container}
                >
                  <Grid item sm={12}>
                    <Typography variant={"h4"} className={classes.title}>
                      Conversation BOT
                    </Typography>
                    <div className={classes.demoform}>
                      <JsonForms
                        schema={schema}
                        uischema={uischema}
                        data={data}
                        renderers={renderers}
                        cells={materialCells}
                        onChange={({ errors, data }) => setData(data)}
                      />
                      <Button
                        className={classes.resetButton}
                        onClick={saveData}
                        color="primary"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant={"h4"} className={classes.title}>
                      Bot Data
                    </Typography>
                    <div className={classes.dataContent}>
                      <pre id="boundData">{stringifiedData}</pre>
                    </div>
                    <Button
                      className={classes.resetButton}
                      onClick={clearData}
                      color="primary"
                      variant="contained"
                    >
                      Clear data
                    </Button>
                  </Grid>
                </Grid>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConversationBot;
