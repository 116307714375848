import React, { useState } from 'react'
import { Button, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box } from '@mui/system';


function Demo() {


    const currencies = [
        {
          value: 'USD',
          label: '$',
        },
        {
          value: 'EUR',
          label: '€',
        },
        {
          value: 'BTC',
          label: '฿',
        },
        {
          value: 'JPY',
          label: '¥',
        },
      ];


      const [showPassword, setShowPassword] = useState(false);
      const [showProjectKey, setshowProjectKey] = useState(false);

      const handleClickShowPassword = () => setShowPassword((show) => !show);
      const handleClickShowProjectKey = () => setshowProjectKey((show) => !show);

      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      }

  return (
    <div className='demo'>

    <div className='demo_top_box'>
        <div>Logo</div>
        <div className='demo_top_box_warningBox'>
            <p><strong>Note:</strong>Demo run will require some secure information based on source or sink selected, if you  dont trust this environment please close the app.
            </p>
            <p>Do not share and commit generated files as it may contain secure information.</p>
        </div>
        <div className='demo_top_box_multifunction'>
            <div className='pipeline' >Pipeline:</div>

            <div className='input_box' >
                <div className='text_field' ><input></input></div>
                <Button variant="outlined"><Typography>Execute</Typography></Button>
            </div>

            <div className='functionality_buttons' >
            <button ><p  className='func_button'>Download (.py)</p></button>
            <button ><p  className='func_button'>Download (.yaml)</p></button>
            </div>
        </div>
    </div>

        <div className='demo_main_area' >
            <div className='demo_main_area_left'>
                
            <form className='left_form'>

            <TextField
                id="Observer/Source"
                select
                label="Select Observer/Source" 
                >
                {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
                ))}
            </TextField>

            <div className='main_area_infoBox'>

                <div className='main_area_infoBox_heading' >
                    <p><strong>Info</strong></p>
                <IconButton disableRipple={true}  onClick={()=>console.log("clicked")} ><AddIcon/></IconButton> 
                </div>

                <div className='MultiLine_input_div' >
                    <div className='input_div_label' >
                        <p>countries</p>
                    <IconButton disableRipple={true} size="small" > <HelpOutlineIcon fontSize='small' sx={{color:"gray"}} /></IconButton>
                    </div>
                    <textarea rows = "3"  name = "description">
                    </textarea>
                </div>

                <div className='input_div' >
                    <div className='input_div_label' >
                        <p>app_id</p>
                    <IconButton disableRipple={true} size="small" > <HelpOutlineIcon fontSize='small' sx={{color:"gray"}} /></IconButton>
                    </div>
                <input id="app_id" ></input>
                </div>

                <div className='input_div' >
                    <div className='input_div_label' >
                        <p>lookup_period</p>
                    <IconButton  disableRipple={true} size="small" > <HelpOutlineIcon fontSize='small' sx={{color:"gray"}} /></IconButton>
                    </div>
                    <input id="lookup_period" ></input>
                </div>


            </div>
            

            </form>

            </div>

            <div className='demo_main_area_middle'>

            <form className='middle_form'>

            <div className='middle_sub_div'>
            <TextField
                id="Analyser"
                select
                label="Select Analyser" 
                >
                {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
                ))}
            </TextField>

            <TextField
                id="Analyser Type"
                select
                label="Analyser Type" 
                >
                {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
                ))}
            </TextField>
            </div>

            <div className='main_area_infoBox'>

                <div className='main_area_infoBox_heading' >
                    <p><strong>Info</strong></p>
                <IconButton disableRipple={true}  onClick={()=>console.log("clicked")} ><AddIcon/></IconButton> 
                </div>

                <div className='MultiLine_input_div' >
                    <div className='input_div_label' >
                        <p>labels</p>
                    <IconButton disableRipple={true} size="small" > <HelpOutlineIcon fontSize='small' sx={{color:"gray"}} /></IconButton>
                    </div>
                    <textarea rows = "3"  name = "description">
                    </textarea>
                </div>

                <FormLabel id="demo-radio-buttons-group-label">Multi_class_classification</FormLabel>
                <RadioGroup 
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value={true} control={<Radio />} label="True" />
                    <FormControlLabel value={false} control={<Radio />} label="False" />
                </RadioGroup>

            

                <div className='input_div' >
                    <div className='input_div_label' >
                        <p>model_name_or_path</p>
                    <IconButton  disableRipple={true} size="small" > <HelpOutlineIcon fontSize='small' sx={{color:"gray"}} /></IconButton>
                    </div>
                    <input id="lookup_period" ></input>
                </div>


            </div>

            </form>

            </div>


            <div className='demo_main_area_right'>
                <form className='right_form' >

                <TextField
                id="Informer/Sink"
                select
                label="Select Informer/Sink" 
                >
                {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
                ))}
            </TextField> 

            <div className='main_area_infoBox'>

                <div className='main_area_infoBox_heading' >
                    <p><strong>Info</strong></p>
                <IconButton disableRipple={true}  onClick={()=>console.log("clicked")} ><AddIcon/></IconButton> 
                </div>


                <div className='input_div' >
                    <div className='input_div_label' >
                        <p>url</p>
                    <IconButton disableRipple={true} size="small" > <HelpOutlineIcon fontSize='small' sx={{color:"gray"}} /></IconButton>
                    </div>
                <input id="url" ></input>
                </div>

                <div className='input_div' >
                    <div className='input_div_label' >
                        <p>user_name</p>
                    <IconButton  disableRipple={true} size="small" > <HelpOutlineIcon fontSize='small' sx={{color:"gray"}} /></IconButton>
                    </div>
                    <input id="user_name" ></input>
                </div>



                    <div>
                        <FormControl fullWidth >
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Password"
                        />
                        </FormControl>
                    </div>

                    <div className='input_div' >
                        <div className='input_div_label' >
                            <p>issue_type.name</p>
                            <IconButton  disableRipple={true} size="small" > <HelpOutlineIcon fontSize='small' sx={{color:"gray"}} /></IconButton>
                        </div>
                        <input id="user_name" ></input>
                    </div>


                    <div>
                        <FormControl fullWidth >
                        <InputLabel htmlFor="outlined-adornment-password">Project.key</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showProjectKey ? 'text' : 'password'}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle projectKey visibility"
                                onClick={handleClickShowProjectKey}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showProjectKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Password"
                        />
                        </FormControl>
                    </div>


            </div>  

                </form>
            </div>

        </div>

    </div>
  )
}

export default Demo
