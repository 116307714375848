import React from "react";
import { useState } from "react";
import Sidebar from "../components/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useEffect } from "react";

export default function EditUser(props) {
  const user = props.userr;
  const [values, setValues] = useState(user);
  const [loader, setloader] = useState(false);
  const [roles, setroles] = useState([]);
  const [department, setdepartment] = useState([]);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const getRoles = async () => {
    const RolesUrl = "https://";
    const data = {};
    try {
      const response = await axios.post(RolesUrl, data);
      setroles(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
  };

  const getDepartment = async () => {
    const departUrl = "https://";
    const data = {};
    try {
      const response = await axios.post(departUrl, data);
      setdepartment(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
  };

  const handleChange = (event) => {
    // if(!event.target.value === "password" && !event.target.value === "Confirmpassword")
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    if (values.password !== values.ConfirmPassword) {
      toast.warning("Password desn't match", toastOptions);
    } else {
      const url = "";
      try {
        const response = axios.post(url, values);
        toast.success(response.data.message, toastOptions);
      } catch (error) {
        toast.error("Something went wrong", toastOptions);
      }
    }
  };

  useEffect(() => {}, [loader]);

  return (
    <div className="all_user_container">
      {loader ? (
        <div className="container-fluid">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="goal">
            <div className="goal_header">
              <h3>Add User</h3>
            </div>
            <div className="goal_body">
              <form className="conversation_form">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  defaultValue={user.name}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />

                <input
                  defaultValue={user.email}
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />

                <select
                  id="User-Type"
                  name="user_type"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                >
                  <option>UserType</option>
                  <option
                    value="internal"
                    selected={user.user_type === "internal"}
                  >
                    Internal
                  </option>
                  <option
                    value="external"
                    selected={user.user_type === "external"}
                  >
                    External
                  </option>
                </select>

                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  defaultValue={"**********"}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />

                <input
                  name="Confirmpassword"
                  defaultValue={"**********"}
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />

                <select
                  name="role_name"
                  id="User-role"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                >
                  <option>Select Role</option>
                  {roles.map((role, index) => (
                    <option
                      selected={user.role_name === role}
                      key={index}
                      value={role}
                    >
                      {role}{" "}
                    </option>
                  ))}
                </select>
                <select
                  name="department_name"
                  id="Department"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                >
                  <option>Select Department</option>
                  {department.map((role, index) => (
                    <option
                      selected={user.department_name === role}
                      key={index}
                      value={role}
                    >
                      {role}{" "}
                    </option>
                  ))}
                </select>
              </form>
            </div>
            <div className="conv_submit">
              <button
                onClick={(event) => handleSubmit(event)}
                className="con_submit"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
