import React from 'react'
import { useEffect } from 'react';
import Sidebar from './components/Sidebar';
import { useNavigate } from 'react-router-dom';
import {FaUserAlt} from 'react-icons/fa'
import {BsFillChatDotsFill} from 'react-icons/bs'
import {BiBot} from 'react-icons/bi'
import {FaUsers} from 'react-icons/fa'
import {TbReportSearch} from 'react-icons/tb'
import {AiOutlineMenu} from 'react-icons/ai'
import DonutChart from 'react-donut-chart';
import { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LineChart from './components/LineChart';

import axios from 'axios';


export default function Home() {

  const [metrices,setmetrices] = useState({})
  const [loader,setloader] = useState(false)
  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const fetchMetrices = async () => {
    setloader(true)
    const client_id = localStorage.getItem("client_id")
    const url = `https://bot.assisto.tech/metrices?client_id=${client_id}`
    try {
      const response = await axios.get(url)
      console.log(response.data)
      setmetrices(response.data)
    }catch(error){
      console.log(error)
      toast.warning("Something went wrong",toastOptions)
    }
    setloader(false)
  }

  useEffect(() => {
    if(Object.keys(metrices).length == 0){
      fetchMetrices()
    }
   },[loader])


  const navigate = useNavigate();
  useEffect(()=> {
    if(!localStorage.getItem("main_token")){
      navigate("/login")
    }
  })

  return (
    <div className="all_user_container">
      {loader ? <div className="container-fluid">
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div> :
      <div className="container-fluid-home">
        <div className="dash_rows">

          <div className="dash_cards">
          <DonutChart  
            data={[
              {
                label: 'Bot Messages',
                value: Math.round((metrices.bot_messages /(metrices.bot_messages + metrices.total_questions)) * 100),
              },
              {
                label: 'In Messages',
                value: Math.round((metrices.total_questions /(metrices.bot_messages + metrices.total_questions)) * 100),
              },
            ]}
            innerRadius="0.4"
            width={420}
            height={350}
          />

          </div>

          {/* <div className="dash_cards">
            <LineChart/>
          </div> */}

        </div>



        <div className="dash_rows">
          <div className="dash_cards">
            <h3>{metrices.active_user}  Active Users</h3>
            <div className="icon_value">
            <FaUserAlt color="blue" fontSize = "1.8em"/>
            </div>
            <p>Active users is a measurement metric that is commonly used to measure the level of engagement for a particular product or object, by quantifying the number of active interactions from visitors within a relevant range of time (daily, weekly and monthly).</p>
          </div>
          <div className="dash_cards">
          <h3>{Math.round(metrices.average_conversation_per_user * 100) / 100}  Average Conv per user</h3>
            <div className="icon_value">
            <BsFillChatDotsFill color="green" fontSize = "1.8em"/>
            </div>
          <p>Average conversation per user is a general metric considering engagement. If your users are increasing and in the meantime, your conversations are not, your average conversation per user will be decreasing.</p>
          </div>
          <div className="dash_cards">
          <h3>{metrices.bot_messages}  Bot Messages</h3>
            <div className="icon_value">
            <BiBot color="red" fontSize = "2.2em"/>
            </div>
          <p>Number of responses generated by BOT in response to user request.</p>
          </div>

          
        </div>

        <div className="dash_rows">

        <div className="dash_cards">
          <h3>{metrices.eng_user}  Engaged Users</h3>
            <div className="icon_value">
            <FaUsers color="aqua" fontSize = "1.8em"/>
            </div>
            <p>User engagement measuring which should give you a better idea of how well your chatbot is performing.</p>
          </div>

          <div className="dash_cards">
            <h3>{metrices.in_messages}  In Messages</h3>
            <div className="icon_value">
            <TbReportSearch color="blue" fontSize = "2.4em"/>
            </div>
            <p>Shows the messages sent by the user. Shows whether the user engages with the chatbot or not.</p>
          </div>
          <div className="dash_cards">
          <h3>{Math.round(parseFloat(metrices.retention_rate)*100)/100}%  Retention Rate</h3>
            <div className="icon_value">
            <TbReportSearch color="blue" fontSize = "2.4em"/>
            </div>
            <p>Retention Rate: This is the percentage of users that return to using the chatbot on the given time frame. This important since we need to keep the customer engaged to extract valuable insight regarding the customers’ preferences.</p>
          </div>

          
        </div>

        <div className="dash_rows">

        <div className="dash_cards">
          <h3>{metrices.newuser}  New User</h3>
            <div className="icon_value">
            <FaUsers color="red" fontSize = "1.8em"/>
            </div>
          <p>New Users: This metric captures the overall success of your chatbot promotion campaign. New users will be necessary to keep an active user number. Customers’ preferences changes over time and the amount of interaction with the chatbot shows an exponential decay.</p>
          </div>

          <div className="dash_cards">
          <h3>{metrices.total_user}  Total User</h3>
            <div className="icon_value">
            <FaUsers color="aqua" fontSize = "1.8em"/>
            </div>
            <p>Total Users: This is the most basic metric. It captures the number of people using your chatbot. This matters because its trend shows the change in the number of users and therefore the amount of data your chatbot has been exposed to</p>
          </div>

          <div className="dash_cards">
            <h3>{metrices.total_questions}  Total Questions</h3>
            <div className="icon_value">
            <BsFillChatDotsFill color="green" fontSize = "1.8em"/>
            </div>
            <p>Total questions: Number of conversations started and successfully completed on a given day. This is the concept engaged users</p>
          </div>

          
        </div>

        <div className='dash_rows' >

        <div className="dash_cards">
          {/* <h3>{metrices.miss_messages.fall_back_rate}  Fall Back Rate</h3> */}
            <div className="icon_value">
            <AiOutlineMenu color="green" fontSize = "1.8em"/>
            </div>
            <p>Miss Messages: Miss messages are the ones our chatbot can’t process.This would be a key metric if the firm starts to engage in countries where the language is used more idiomatically.</p>
          </div>
          <div className="dash_cards">
          {/* <h3>{metrices.miss_messages.miss_messages}  Miss Messages</h3> */}
            <div className="icon_value">
            <AiOutlineMenu color="blue" fontSize = "2.2em"/>
            </div>
          <p>Fall back rate when BOT fails to function it perform some default action. This rate demonstrates how many times that happened.</p>
          </div>
      
        </div>

      </div>
    }
    <ToastContainer />
    </div>

  )
}
