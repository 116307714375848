import React from 'react'
import Sidebar from '../components/Sidebar'
import { useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function AddRole() {
    const [loader,setloader] = useState(false)
    const toastOptions = {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      };

    const [values, setValues] = useState({
        role_name: "",
        role_description : ""
    })

    const handleChange = (event) => {
        // if(!event.target.value === "password" && !event.target.value === "Confirmpassword")
        setValues({ ...values, [event.target.name]: event.target.value });
      };

    const handleAdd =  async (event) => {
        setloader(true)
        const url = "https://bot.assisto.tech/addrole"
        const data = {...values, client_id : localStorage.getItem("client_id")}

        try{
            const response = await axios.post(url,data)
            console.log(response.data)
            toast.success(response.data.message,toastOptions)
        }catch(error){
            console.log(error)
            toast.warning("Sorry something went wrong", toastOptions)
        }
        setloader(false)

    }

    return (
        <div className="all_user_container">
            
            {loader ? <div className="container-fluid">
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div> :
            <div className="container-fluid">
                <div className="goal">
                    <div className="goal_header">
                        <h3>Add Role</h3>
                    </div>
                    <div className="goal_body">
                        <form className="conversation_form">
                        <input type="text" placeholder="Role Name" name='role_name' onChange={(event) => {handleChange(event)}}/>
                        <input type="text" placeholder="Description for role" name='role_description' onChange={(event) => {handleChange(event)}}/>                       
                        </form>
                    
                    </div>
                    <div className="conv_submit">
                        <button onClick={(event) => handleAdd(event)} className='con_submit'>Add</button>
                    </div>
                </div>
                
            </div>
            }
            <ToastContainer />
        </div>
    
  )
}



