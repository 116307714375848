import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Sidebar from "../components/Sidebar";
import { AiFillDelete } from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import EditUser from "./EditUser";

export default function AllUsers() {
  const [loader, setloader] = useState(false);
  const [edit, setedit] = useState(false);
  const [editval, seteditval] = useState({});
  const [alluser, setalluser] = useState([
    {
      name: "abcd",
      email: "abcd",
      user_type: "external",
      department_name: "asdf",
      role_name: "jfhfgf",
      password: "12345",
      confirmPassword: "12345",
    },
    {
      name: "abcd",
      email: "abcd",
      user_type: "external",
      department_name: "asdf",
      role_name: "jfhfgf",
      password: "12345",
      confirmPassword: "12345",
    },
  ]);
  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const handleEdit = (event, index) => {
    setedit(true);
    seteditval(alluser[index]);
  };

  const submit = (event, index) => {
    confirmAlert({
      title: "Delete User",
      message: "Are you sure you want to delete this User",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(event, index),
        },
        {
          label: "No",
          // onClick: () => alert("Click No")
        },
      ],
    });
  };

  const FetchUsers = async () => {
    setloader(true);
    const url = "";
    const data = {
      client_id: localStorage.getItem("client_id"),
    };
    try {
      const response = await axios.post(url, data);
      setalluser(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
    setloader(false);
  };

  const handleDelete = async (event, index) => {
    setloader(true);
    const url = "";
    const data = alluser[index];
    try {
      const response = await axios.post(url, data);
      toast.success(response.data.message, toastOptions);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong", toastOptions);
    }
    setloader(false);
  };

  useEffect(() => {
    //TODO
  }, [loader]);

  if (edit) {
    return <EditUser userr={editval} />;
  } else {
    return (
      <div class="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">All Users</h1>

        <table className="table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Email Address</th>
              <th>User Type</th>
              <th>Department</th>
              <th>Role</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {alluser.map((user, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.user_type}</td>
                  <td>{user.department_name}</td>
                  <td>{user.role_name}</td>
                  <td>
                    <FaUserEdit onClick={(event) => handleEdit(event, index)} />
                  </td>
                  <td>
                    <AiFillDelete onClick={(event) => submit(event, index)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

/*return (
      <div className="all_user_container">
          <Sidebar />
          {loader ? <div className="container-fluid">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div> :
          <div className="container-fluid">
            <div className="goal">
              <div className="goal_header">
                <h3>Users</h3>
                <div className="goal_body">
                  {/* <div className="conversation_form"> * /}
                  <table className="table">
                    <thead>
                        <tr>
                            <th>S.N</th>
                            <th>Name</th>
                            <th>Email Address</th>
                            <th>User Type</th>
                            <th>Department</th>
                            <th>Role</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                          {alluser.map((user,index) => {
                            return <tr key={index}>
                              <td>{index +1}</td>
                              <td>{user.name}</td>
                              <td>{user.email}</td>
                              <td>{user.user_type}</td>
                              <td>{user.department_name}</td>
                              <td>{user.role_name}</td>
                              <td><FaUserEdit onClick={(event) => handleEdit(event,index)} /></td>
                              <td><AiFillDelete onClick={(event) => submit(event,index)} /></td>
                            </tr>
                          })}
                        </tbody>
                  </table>
                  {/* </div> * /}
                </div>
              </div>
            </div>
          </div>
          }
          <ToastContainer />
                        
      </div>
    )*/
