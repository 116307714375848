import React from 'react'
import { useState } from 'react'
import Sidebar from '../components/Sidebar'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from 'react';
import axios from 'axios';

export default function RetrainBotFiles() {
  const [languages,setlanguages] = useState([])
  const [selectedlang,setselectedlang] = useState("")
  const [loader,setloader] = useState(false)

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const getLanguages = async () => {
    setloader(true)
    const url = "https://bot.assisto.tech/supported_langs"
    try{
        const response = await axios.get(url)
        setlanguages(response.data.data)
        console.log(response.data.data)
        setloader(false)
    } catch(error) {
        console.log(error)
        toast.warning("Something went wrong",toastOptions)
        setloader(false)
    }

  }

  const handleTrain = (event) => {
    const url = "retrain_bot"
    var formData = new FormData();
    var csvfile = document.querySelector('#upload');
    formData.append("file", csvfile.files[0]);
    try{
      axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
      }
      
    })
    }catch(error){
      console.log(error)
      toast.warning("something went wrong",toastOptions)
    }
      
    }

  const handleLang = (event) => {
    setselectedlang(event.target.value)
  }

  const handleFiles =(event) => {
    console.log(event.target.files[0])
  }


  useEffect(() => {
    if(languages.length === 0){
      getLanguages()
    }
  })

  return (
    <div className="all_user_container">
      <Sidebar />
      {loader ? <div className="container-fluid">
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>:
      <div className="container-fluid">
        <div className="goal">
          <div className="goal_header">
            <h3>Retrain from Files</h3>
          </div>
          <div className="goal_body">
            <form className="conversation_form_ans">

            <select onChange={(event) => handleLang(event)} className='item' id='User-Type' name='language' >
              <option>Select Language</option>
              {
                  languages.map((lang,index) => {
                      return (
                      <option  key={index} value={lang}>{lang}</option>
                      )
                  })
              }
              
          </select>
            <input onChange={(event) => handleFiles(event)} id="upload" type="file"/>
            </form>            
          </div>
          <div className="conv_submit">
            <button onClick={(event) => handleTrain(event)} className='con_submit'>Train</button>
            </div>
          
        </div>
        
      </div>
      }
      <ToastContainer />
    </div>
    
  )
}
