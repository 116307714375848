import axios from "axios";
import React from "react";
import { useState } from "react";
import Sidebar from "../components/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import Select from "react-select";
export default function AddPermissionToRole() {
  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const [loader, setloader] = useState(false);
  const [operation, setoperation] = useState("");
  const [role, setrole] = useState([]);
  const [selectedrole, setselectedrole] = useState("");
  const [all_intent, setall_intent] = useState([]);
  const [selectedintent, setselectedintent] = useState([]);

  const getRole = async () => {
    setloader(true);
    const url = "https://bot.assisto.tech/getrole";
    const data = { client_id: localStorage.getItem("client_id") };
    try {
      const response = await axios.post(url, data);
      setrole(response.data);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong", toastOptions);
    }
    setloader(false);
  };

  const getIntent = async () => {
    // setloader(true)
    const url = "https://bot.assisto.tech/getintent";
    const data = { client_id: localStorage.getItem("client_id") };
    try {
      const response = await axios.post(url, data);
      const inten = response.data.map((str, index) => ({
        value: index + 1,
        label: str,
      }));
      setall_intent(inten);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong", toastOptions);
    }
    // setloader(false)
  };

  const handleRole = (event) => {
    setselectedrole(event.target.value);
    getIntent();
  };

  const handleIntent = (e) => {
    setselectedintent(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleOperation = (event) => {
    setoperation(event.target.value);
  };

  const handleSubmit = async (event) => {
    setloader(true);
    if (
      selectedintent.length === 0 ||
      selectedrole === "" ||
      operation === ""
    ) {
      toast.warning("Please select all values", toastOptions);
    } else {
      const url = "https://bot.assisto.tech/addpermission";
      const data = {
        client_id: localStorage.getItem("client_id"),
        role_name: selectedrole,
        intent_name: selectedintent,
      };
      try {
        const response = await axios.post(url, data);
        toast.success(response.data.message, toastOptions);
      } catch (error) {
        console.log(error);
        toast.warning("something went wrong", toastOptions);
      }
    }
    setloader(false);
  };

  useEffect(() => {
    if (role.length === 0) {
      getRole();
    }
  }, [loader]);

  return (
    <div className="all_user_container">
      {loader ? (
        <div className="container-fluid">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="goal">
            <div className="goal_header">
              <h3>Add Permission to Role</h3>
            </div>
            <div className="goal_body">
              <form className="conversation_form">
                <select onChange={(event) => handleRole(event)} name="user">
                  <option> Select Role</option>
                  {role.map((role, index) => (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  ))}
                </select>

                <select
                  onChange={(event) => handleOperation(event)}
                  name="user"
                >
                  <option>Select Operations</option>
                  <option value="Internal">Edit</option>
                  <option value="External">Read</option>
                </select>

                <Select
                  className="dropdown"
                  options={all_intent}
                  value={all_intent.filter((obj) =>
                    selectedintent.includes(obj.value)
                  )}
                  onChange={(event) => handleIntent(event)}
                  isMulti
                  isClearable
                />
              </form>
            </div>
            <div className="conv_submit">
              <button
                onClick={(event) => handleSubmit(event)}
                className="con_submit"
                type="submit"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
