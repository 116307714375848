import React from 'react'
import Sidebar from '../components/Sidebar'
import { MdCancel } from 'react-icons/md'
import { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from 'react'


export default function AddUtterence() {

    const [loader,setloader] = useState(false)
    const toastOptions = {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      };
    const [utterence,setutterence] = useState(["i am i am","you are you are"])
    const [addutterence,setaddutterence] = useState("")

    const utterenceChange = (event) => {
        setaddutterence(event.target.value)
    }

    const getUtterence = async () => {
        const url = "https://bot.assisto.tech/apis/get_utterences"
        const data = {
            client_id : localStorage.getItem("client_id")
        }
        try{
            const response = await axios.post(url,data)
            setutterence(response.data.response)
        }catch(error){
            console.log(error)
            toast.warning("API is down",toastOptions)
        }
    }



    const addUtterence = async (text) => {
        setloader(false)
        const url = "https://bot.assisto.tech/aapis/save_goal"
        const data = {
            client_id : localStorage.getItem("client_id"),
            goal : text
        }
        try {
            const response = await axios.post(url,data)
        }catch(error){
            console.log(error)
            toast.warning("The API is down",toastOptions)
        }
        setloader(true)
    }

    useEffect(() => {
        // getUtterence()
    },[loader])

  return (
    <div className="all_user_container">
        
        {loader ? <div className="container-fluid">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div> :
        <div className="container-fluid">
            <div className="goal">
                <div className="goal_header">
                    <h3>Add Utterence</h3>
                </div>
                <div className="goal_body">
                    <div className="conversation_form_intent">
                        <input onChange={(event) => utterenceChange(event)} placeholder='Enter new Utterence' />
                        <button className='intent_button'>Add</button>
                    </div>
                    <div className="to_do_container">
                        <div className="to_donsingle">
                        {utterence.map((g,index) => {
                            return <div key={index} className="message_intent">
                                <p>{g}</p>
                                <div className="iconns">
                                    <MdCancel size={"18px"} />
                                </div>
                            </div>
                        })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        <ToastContainer />
    </div>
  )
}
