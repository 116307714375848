import axios from 'axios'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from '../components/Sidebar'
import {AiFillDelete } from "react-icons/ai";

export default function Notifications() {

    const [notifications,setnotifications] = useState(["ak EFgushAGfuk hdyd hqggc vjfvh", "hsvba jvcgduf suahs",
                                                        "hbd adudfbav uvac ugbac  uigc", "bhjsa ugas fb uiagd"])
    const [loader,setloader] = useState(false)
    const toastOptions = {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      };


    const AllNotification = async () => {
        const url = "https://bot.assisto.tech/apis/notifications"
        const data = {client_id : localStorage.getItem("client_id")}
        try{
            const response = await axios.post(url,data)
            setnotifications(response.data)
        }catch(error){
            console.log(error)
            toast.error("Something went wrong",toastOptions)
        }
    }

    useEffect(() =>{
        // AllNotification()
    },[loader])

  return (
    <div className="all_user_container">
        <Sidebar />
        {loader ? <div className="container-fluid">
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div> :
        <div className="container-fluid">
            <div className="goal">
                <div className="goal_header">
                    <h3>All Notifications</h3>
                </div>
                <div className="goal_body">
                    <div className="conversation_form">
                    <ul>
                    {notifications.map((not,index) => {
                        return <div key={index} className="notification">
                            <li class="notifi">
                            {not}
                            </li>
                        </div>
                    })}
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    }
    <ToastContainer />
    </div>
  )
}
