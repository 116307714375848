import React from 'react'
import { ReactComponent as User } from '../images/undraw_profile.svg'

export default function Profile() {
  return (
    <div className="Profile">
        <div className="user_photo">
            <User />
        </div>
        <div className="user_details">
            <h4 className='email'>{localStorage.getItem("email")}</h4>
            <p className='name'>{localStorage.getItem("name")}</p>
        </div>
    </div>
  )
}
