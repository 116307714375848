import { Button } from '@mui/material'
import axios from 'axios';
import React from 'react'
import { useNavigate } from "react-router-dom"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function TrainYourBot() {

    const navigate = useNavigate();

    const toastOptions = {
      position: "bottom-right",
      autoClose: 8000,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    };


    const callTrainApi = async () => {
        toast.success("Data sent for training successfully ", toastOptions);
        console.log("Train api called");
        navigate("/conversation_bot")
        // const url = "https://bot.assisto.tech/train";
    
        // try {
        //   const data = {
        //     client_id: localStorage.getItem("client_id"),
        //   };
        //   const response = await axios.post(url, data);
        //   console.log("response message", response.data);
        // } catch (error) {
        //   console.log(error);
        // }
      };



  return (
    <div >
    
    <div className='TrainBotBox'>
        <div className='TrainBox_header'>
           <h5>Train Your Bot</h5>
        </div>
        <div className='train_body'>
            <h6>Congratulations Done Successfully Now you have to train the data</h6>

                    <Button
                    sx={{margin:"1rem"}}
                        onClick={callTrainApi}
                        color="primary"
                        variant="contained"
                      >
                        Train
                      </Button>
                    <div>
                    </div>
                     
        </div>



    </div>
    <ToastContainer />
    </div>
  )
}

