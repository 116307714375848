import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RetrainBotFiles from "./RetrainBotFiles";

export default function Retrain_bot() {
  const [loader, setloader] = useState(false);
  const [sentences, setsentences] = useState([]);
  const [languages, setlanguages] = useState([]);
  const [intent, setintent] = useState([]);
  const [selectedintent, setselectedintent] = useState("");
  const [selectedsent, setselectedsent] = useState("");
  const [selectedlang, setselectedlang] = useState("");
  const [timestamp, settimestamp] = useState("");
  const [step, setstep] = useState("");
  const [retrainfiles, setretrainfiles] = useState(false);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const getLanguages = async () => {
    setloader(true);
    const url = "https://bot.assisto.tech/supported_langs";
    try {
      const response = await axios.get(url);
      setlanguages(response.data.data);
      console.log(response.data.data);
      setloader(false);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong", toastOptions);
      setloader(false);
    }
  };

  const getSentences = async () => {
    const id = localStorage.getItem("client_id");
    const url = `https://bot.assisto.tech/missed_sentences?client_id=${id}`;
    setloader(true);
    try {
      const response = await axios.get(url);
      console.log(response.data.data);
      setsentences(response.data.data);
      setloader(false);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong", toastOptions);
      setloader(false);
    }
  };

  const getIntents = async () => {
    const id = localStorage.getItem("client_id");
    const url = `https://bot.assisto.tech/get_intents?client_id=${id}&language=${selectedlang}&misssentences=${selectedsent}`;
    setloader(true);
    try {
      const response = await axios.get(url);
      setintent(response.data.data);
      setloader(false);
    } catch (error) {
      console.log(error);
      toast.warning("Soory! Something went wrong", toastOptions);
      setloader(false);
    }
  };

  const TrainApi = async () => {
    setloader(true);

    const url = "https://bot.assisto.tech/retrain_bot";
    const client_id = localStorage.getItem("client_id");

    const data = {
      misssentences: selectedsent,
      language: selectedlang,
      intents: [selectedintent],
      client_id: client_id,
      time_stamp: timestamp,
    };
    try {
      const response = await axios.post(url, data);
      const send = response.data.message;
      toast.success(send, toastOptions);
      setloader(false);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong", toastOptions);
      setloader(false);
    }
  };

  const handleFile = (event) => {
    setretrainfiles(!retrainfiles);
  };

  function Timestamp(value) {
    const upd = sentences.filter((sent) => {
      return sent.question === value;
    });
    console.log(upd[0].time);
    settimestamp(upd[0].time);
  }

  const handleSelectedSent = (event) => {
    setselectedsent(event.target.value);
    Timestamp(event.target.value);
    getLanguages();
    setstep("language");
  };

  const handleSelectedLang = (event) => {
    setselectedlang(event.target.value);
    getIntents();
    setstep("intent");
  };

  const handleSelectedIntent = (event) => {
    setselectedintent(event.target.value);
    setstep("train");
  };

  const handleTrain = (event) => {
    if (step !== "train") {
      toast.warn("Select an Intent", toastOptions);
    } else {
      TrainApi();
    }
  };

  useEffect(() => {
    if (sentences.length === 0) {
      getSentences();
    }
  }, [loader, step]);

  if (retrainfiles) {
    return <RetrainBotFiles />;
  } else {
    return (
      <div className="all_user_container">
        {loader ? (
          <div className="container-fluid">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="goal">
              <div className="goal_header">
                <h3>Retrain Bot</h3>
              </div>
              <div className="goal_body">
                <div className="retrain">
                  <input
                    type="checkbox"
                    name="advance_options"
                    onChange={(event) => handleFile(event)}
                  />
                  <label htmlFor="advance_option"> Retrain from File </label>
                </div>

                {step === "intent" ? (
                  <form className="conversation_form_ans">
                    <select
                      onChange={(event) => handleSelectedIntent(event)}
                      className="item"
                      id="User-Type"
                      name="language"
                    >
                      <option>Select Intent</option>
                      {intent.map((int, index) => {
                        return (
                          <option key={index} value={int}>
                            {int}
                          </option>
                        );
                      })}
                    </select>
                  </form>
                ) : (
                  <form className="conversation_form_ans">
                    {step === "" ? (
                      <select
                        onChange={(event) => handleSelectedSent(event)}
                        className="item"
                        id="User-Type"
                        name="language"
                      >
                        <option>Select Sentence</option>
                        {sentences.map((sent, index) => {
                          return (
                            <option key={index} value={sent.question}>
                              {sent.question}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        onChange={(event) => handleSelectedLang(event)}
                        className="item"
                        id="User-Type"
                        name="language"
                      >
                        <option>Select Language</option>
                        {languages.map((lang, index) => {
                          return (
                            <option key={index} value={lang}>
                              {lang}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </form>
                )}
              </div>

              {step === "intent" ? (
                <div className="conv_submit">
                  <button
                    onClick={(event) => handleTrain(event)}
                    className="con_submit"
                  >
                    Train
                  </button>
                </div>
              ) : (
                <div className="empty"></div>
              )}
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
    );
  }
}
