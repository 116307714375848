import React from 'react'
import Sidebar from '../components/Sidebar'
import { useState } from 'react';
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddGreetings() {

    const [loader,setloader] = useState(false)
    const [training,settraining] = useState(false)
    const toastOptions = {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      };

    const [values, setValues] = useState({
        goal: "greetings",
        intent : "",
        language : "",
        sub_intent : "",
        question: "",
      });

    const [message,setMessage] = useState("")

    const handleChange = (event) =>{
        setValues({...values,[event.target.name] : event.target.value})
    }

    const handleMessage = (event) => {
        setMessage(event.target.value)
    }

    const handleSubmit = async (event) => {
        setloader(true)
        const client_id = localStorage.getItem("client_id")
        const url = `https://bot.assisto.tech/configdata?client_id=${client_id}`

        const data = {...values, message : message}
        console.log(data)
        // try{
        //     const response = await axios.post(url,data)
        //     toast.success(response.data.Message,toastOptions)
        //     settraining(true)        
        // }catch(error){
        //     console.log(error)
        //     toast.warning("Sorry Something went wrong",toastOptions)

        // }
        setloader(false)
    }

  return (
    <div className="all_user_container">
        {loader ? <div className="container-fluid">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div> :
        <div className="container-fluid">
            <div className="goal">
                <div className="goal_header">
                    <h3>Add Greetings</h3>
                </div>
                <div className="goal_body">
                <form className="conversation_form">

                <input value={"greetings"} className='gbody' type="text" placeholder="Goal" name='goal' />
                <input  className='gbody' type="text" placeholder="Intent" name='intent' onChange={(event) => {handleChange(event)}}/>

                {/* Language */}
                <select  className='item' id='User-Type' name='language' onChange={event => handleChange(event)}>
                <option>Language</option>
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
                </select>

                <input  className='gbody' type="text" placeholder="Sub Intent" name='sub_intent' onChange={(event) => {handleChange(event)}}/>
                <input className='gbody' type="text" placeholder="Question" name='question' onChange={(event) => {handleChange(event)}} />
                </form>

                <div className="slot_header">
                    <h3>Action Message</h3>
                </div>

                <form className='conversation_form_ans'>
                <input placeholder='Message' onChange={(event) => handleMessage(event)}/>
                </form>
                </div>

                <div className="conv_submit">
                <button onClick={(event) => handleSubmit(event)} className='con_submit'>Submit</button>
                </div>

            </div>
        </div>
        }
        <ToastContainer />
    </div>
  )
}
