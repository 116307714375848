import "./App.css";
import "./spinner.css";
import Register from "./Register";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Home from "./Home";
import AllUsers from "./pages/AllUsers";
import AddUser from "./pages/AddUser";
import AddPermissionToRole from "./pages/AddPermissionToRole";
import AddRole from "./pages/AddRole";
import ConversationBot from "./pages/ConversationBot";
import EditGoal from "./pages/EditGoal";
import Logout from "./pages/Logout";
import Retrain_bot from "./pages/Retrain_bot";
import AddDepartment from "./pages/AddDepartment";
import Notifications from "./pages/Notifications";
import AddIntent from "./pages/AddIntent";
import AddUtterence from "./pages/AddUtterence";
import AddFaq from "./pages/AddFaq";
import AddGreetings from "./pages/AddGreetings";
import Pipeline from "./pages/Pipeline";
import Demo from "./pages/Demo";
import ConfigOptions from "./pages/ConfigOptions";
import MaskLanguageModel from "./pages/MaskLanguageModel";
import MaskModelling from "./pages/MaskModelling";
import ModelDistillation from "./pages/ModelDistillation";
import ModelTypes from "./pages/ModelTypes";
import TrainYourBot from "./components/TrainYourBot";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/users" element={<AllUsers />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route
          path="/add_permission_to_role"
          element={<AddPermissionToRole />}
        />
        <Route path="/add_role" element={<AddRole />} />
        <Route path="/conversation_bot" element={<ConversationBot />} />
        <Route path="/demo" element={<Demo />} />

        <Route path="/edit_goal" element={<EditGoal />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/retrain_bot" element={<Retrain_bot />} />
        <Route path="/add_department" element={<AddDepartment />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/add_intent" element={<AddIntent />} />
        <Route path="/add_utterence" element={<AddUtterence />} />
        <Route path="/faq" element={<AddFaq />} />
        <Route path="/trainYourBot" element={<TrainYourBot />} />
        <Route path="/greetings" element={<AddGreetings />} />
        <Route path="/pipeline" element={<Pipeline />} />
        <Route path="/configoptions" element={<ConfigOptions />} />
        <Route path="/modeldistillation" element={<ModelDistillation />} />
        <Route path="/masklanguagemodel" element={<MaskLanguageModel />} />
        <Route path="/maskmodelling" element={<MaskModelling />} />
        <Route path="/modeltypes" element={<ModelTypes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
