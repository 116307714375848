import React from 'react'
import Sidebar from '../components/Sidebar'
import { MdCancel } from 'react-icons/md'
import { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from 'react'

export default function AddIntent() {

    const [loader,setloader] = useState(false)
    const toastOptions = {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      };

    const [goal,setgoal] = useState([])
    const [intent,setintent] = useState([])
    const [secondintent,setsecondintent] = useState([])

    const [addgoal,setaddgoal] = useState("")
    const [addintent,setaddintent] = useState("")
    const [addsecondintent,setaddsecondintent] = useState("")

    const goalChange = (event) => {
        setaddgoal(event.target.value)
    }

    const intentChange = (event) => {
        setaddintent(event.target.value)
    }

    const secondIntentChange = (event) =>{
        setaddsecondintent(event.target.value)
    }

    const getGoal = async () => {
        const url = "https://bot.assisto.tech/aapis/get_goal"
        const data = {
            client_id : localStorage.getItem("client_id")
        }
        try{
            const response = await axios.post(url,data)
            setgoal(response.data.response)
        }catch(error){
            console.log(error)
            toast.warning("API is down",toastOptions)
        }
    }
    const getIntent = async () => {
        const url = "https://bot.assisto.tech/aapis/get_intents"
        const data = {
            client_id : localStorage.getItem("client_id")
        }
        try{
            const response = await axios.post(url,data)
            setintent(response.data.response)
        }catch(error){
            console.log(error)
            toast.warning("API is down",toastOptions)
        }
    }
    const getSecondIntent = async () => {
        const url = "https://bot.assisto.tech/aapis/get_secondary_intent"
        const data = {
            client_id : localStorage.getItem("client_id")
        }
        try{
            const response = await axios.post(url,data)
            setsecondintent(response.data.response)
        }catch(error){
            console.log(error)
            toast.warning("API is down",toastOptions)
        }
    }

    const addGoal = async (text) => {
        setloader(false)
        const url = "https://bot.assisto.tech/aapis/save_goal"
        const data = {
            client_id : localStorage.getItem("client_id"),
            goal : text
        }
        try {
            const response = await axios.post(url,data)
        }catch(error){
            console.log(error)
            toast.warning("The API is down",toastOptions)
        }
        setloader(true)
    }

    const addIntent = async (text) => {
        setloader(false)
        const url = "https://bot.assisto.tech/aapis/save_goal"
        const data = {
            client_id : localStorage.getItem("client_id"),
            goal : text
        }
        try {
            const response = await axios.post(url,data)
        }catch(error){
            console.log(error)
            toast.warning("The API is down",toastOptions)
        }
        setloader(true)
    }

    const addSecondIntent = async (text) => {
        setloader(false)
        const url = "https://bot.assisto.tech/aapis/save_goal"
        const data = {
            client_id : localStorage.getItem("client_id"),
            goal : text
        }
        try {
            const response = await axios.post(url,data)
        }catch(error){
            console.log(error)
            toast.warning("The API is down",toastOptions)
        }
        setloader(true)
    }

    const getALL = () =>{
        getGoal()
        getIntent()
        getSecondIntent()
    }

    useEffect(() => {
        getALL()
    },[loader])

  return (
    <div className="all_user_container">
        {loader ? <div className="container-fluid">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div> :
        <div className="container-fluid">
        <div className="goal">
            <div className="goal_header">
                <h3>Add Intent</h3>
            </div>
            <div className="goal_body">
                <div className="conversation_form_intent">
                    <input onChange={(event) => goalChange(event) } placeholder='Goal'/>
                    <button  className='intent_button'>Add</button>
                    <input onChange={(event) => intentChange(event) } placeholder='Intent'/>
                    <button className='intent_button' >Add</button>
                    <input onChange={(event) => secondIntentChange(event) } placeholder='Secondary Intent'/>
                    <button className='intent_button'>Add</button>

                </div>

                <div className="to_do_container">
                    <div className="to_donsingle">
                    {goal.map((g,index) => {
                            return <div key={index} className="message_intent">
                                <p>{g}</p>
                                <div className="iconns">
                                    <MdCancel size={"18px"} />
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="to_donsingle">
                    {intent.map((g,index) => {
                            return <div key={index} className="message_intent">
                                <p>{g}</p>
                                <div className="iconns">
                                    <MdCancel size={"18px"} />
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="to_donsingle">
                    {secondintent.map((g,index) => {
                            return <div key={index} className="message_intent">
                                <p>{g}</p>
                                <div className="iconns">
                                    <MdCancel size={"18px"} />
                                </div>
                            </div>
                        })}
                    </div>
                </div>

            </div>
        </div>
        </div>
        }
        <ToastContainer/>
    </div>
  )
}
