import React from 'react'
import "../App.css"
import { SidebarData } from './SidebarData'
import Profile from './Profile'
import { useState } from 'react'
import { SidebarOptions } from './SidebarData'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export default function Sidebar() {
  const navigate = useNavigate()

  const [clicked,setclicked] = useState(false)
  const [selected, setselected] = useState("")

  const handleCLicked = (event) => {
    if(event.target.innerHTML === "Logout"){
      navigate("/logout")
    }
    else if(event.target.innerHTML === "Dashboard"){
      navigate("/")
    }
    else if(event.target.innerHTML === "Notifications"){
      navigate("/notifications")
    }
    else if (event.target.innerHTML === "Pipeline"){
      navigate("/pipeline")
    }
    else if(clicked && event.target.innerHTML === selected){
      setselected("")
      setclicked(false)
    }else{
      setclicked(true)
      const option = SidebarOptions[event.target.innerHTML]
      console.log(option)
      setselected(event.target.innerHTML)
    }
  }

  useEffect(() => {

  },[selected,clicked])

  return (
    <div className='Sidebar'>
        <Profile />
        <ul  className='SidebarList' >
        {SidebarData.map((data,key) => {
        return <div key={key}  className="row-container">
        <li className='row'
            onClick={(event) => {handleCLicked(event)}} >
            <div id="icon">
                {data.icon}
            </div>
            <div id="title">
                {data.title}
            </div>
        </li>
        {selected !== data.title ? <div className="options-hide"></div> : <div className="options">
        {SidebarOptions[selected].map((value,index2) =>{
          return <div key={index2} className='options_value' onClick={() => {window.location.pathname = value.link}} >
            {value.title}
          </div>
        }
      )}
        </div>}
        </div> 
        })}
        
        </ul>
        
    </div>
  )
}
