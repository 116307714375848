import React from "react";
import Sidebar from "../components/Sidebar";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

export default function AddDepartment() {
  const [loader, setloader] = useState(false);
  const [department, setdepartment] = useState("");
  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const handleDepartment = (event) => {
    setdepartment(event.target.value);
  };

  const handleAdd = async (event) => {
    setloader(true);
    const url = "http://bot.assisto.tech/adddepartment";
    const data = {
      department_name: department,
      client_id: localStorage.getItem("client_id"),
    };

    try {
      const response = await axios.post(url, data);
      console.log(response);
      toast.success(response.data.Message);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong", toastOptions);
    }
    setloader(false);
  };

  useEffect(() => {}, [loader]);

  return (
    <div class="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Add Department</h1>
      <form>
        <input
          type="text"
          placeholder="Add Department Name"
          onChange={(event) => handleDepartment(event)}
        />
      </form>
      <button class="btn btn-primary" onClick={(event) => handleAdd(event)}>
        Add
      </button>
    </div>
  );
}

/* return (
    <div className="all_user_container">
        <Sidebar />
        {loader ? <div className="container-fluid">
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div> :
        <div className="container-fluid">
            <div className="goal">
                <div className="goal_header">
                    <h3>Add Department</h3>
                </div>
                <div className="goal_body">
                    <form className='conversation_form_ans'>
                    <input type="text" placeholder="Add Department Name" onChange={(event) => handleDepartment(event)}/>
                    </form>
                </div>
                <div className="conv_submit">
                    <button onClick={(event) => handleAdd(event)} className='con_submit'>Add</button>
                </div>
            </div>
            
        </div>
        }
        <ToastContainer/>
    </div>
  )*/
