import React from "react";
import { useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

export default function AddUser() {
  const [loader, setloader] = useState(false);
  const [roles, setRoles] = useState([]);
  const [department, setDepartment] = useState([]);
  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const [values, setValues] = useState({
    name: "",
    email: "",
    user: "",
    password: "",
    Confirmpassword: "",
    role: "",
    department: "",
  });

  const handleChange = (event) => {
    // if(!event.target.value === "password" && !event.target.value === "Confirmpassword")
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const getRoles = async () => {
    const RolesUrl = "https://";
    const data = {};
    try {
      const response = await axios.post(RolesUrl, data);
      setRoles(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
  };

  const getDepartment = async () => {
    const departUrl = "https://";
    const data = {};
    try {
      const response = await axios.post(departUrl, data);
      setDepartment(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
  };
  useEffect(() => {}, [loader]);

  const handleSubmit = async (event) => {
    setloader(true);
    const url = "https://bot.assisto.tech/adduser?client_id=client_id";

    try {
      const response = await axios.post(url, values);
      toast.success(response.data.message, toastOptions);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong", toastOptions);
    }
    setloader(false);
  };
  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Add Users</h1>
      <form className="conversation_form">
        <input
          type="text"
          placeholder="Name"
          name="name"
          onChange={(event) => {
            handleChange(event);
          }}
        />

        <input
          type="email"
          placeholder="Email"
          name="email"
          onChange={(event) => {
            handleChange(event);
          }}
        />

        <select
          id="User-Type"
          name="user"
          onChange={(event) => {
            handleChange(event);
          }}
        >
          <option>UserType</option>
          <option value="Internal">Internal</option>
          <option value="External">External</option>
        </select>

        <input
          name="password"
          type="password"
          placeholder="Password"
          onChange={(event) => {
            handleChange(event);
          }}
        />

        <input
          name="Confirmpassword"
          type="password"
          placeholder="Confirm Password"
          onChange={(event) => {
            handleChange(event);
          }}
        />

        <select
          name="role"
          id="User-role"
          onChange={(event) => {
            handleChange(event);
          }}
        >
          <option>Select Role</option>
          {roles.map((role, index) => (
            <option key={index} value={role}>
              {role}
            </option>
          ))}
        </select>
        <select
          name="department"
          id="Department"
          onChange={(event) => {
            handleChange(event);
          }}
        >
          <option>Select Department</option>
          {department.map((role, index) => (
            <option key={index} value={role}>
              {role}
            </option>
          ))}
        </select>
      </form>
      <button onClick={(event) => handleSubmit(event)} class="btn btn-primary">
        Add
      </button>
    </div>
  );
}

/*return (
    <div className="all_user_container">
        {/* <Sidebar /> * /}
        {loader ? <div className="container-fluid">
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div> :
      <div className="container-fluid">
        <div className="goal">
            <div className="goal_header">
                <h3>Add User</h3>
            </div>
            <div className="goal_body">
                <form className="conversation_form">
                <input   type="text" placeholder="Name" name='name' onChange={(event) => {handleChange(event)}}/>
                    
                    <input   type="email" placeholder='Email' name='email' onChange={(event) => {handleChange(event)}} />
                    
                    <select  id='User-Type' name='user' onChange={(event) => {handleChange(event)}}>
                    <option>UserType</option>
                    <option value="Internal">Internal</option>
                    <option value="External">External</option>
                    </select>

                    <input name='password' type="password" placeholder='Password' onChange={(event) => {handleChange(event)}}/>
                    
                    <input name='Confirmpassword'  type="password" placeholder='Confirm Password'onChange={(event) => {handleChange(event)}} />
                    
                    <select name='role' id='User-role' onChange={(event) => {handleChange(event)}}>
                    
                    <option>Select Role</option>
                    {roles.map((role,index) =>
                        <option  key={index} value={role}>{role}</option>
                    )}
                    </select>
                    <select name='department'  id='Department' onChange={(event) => {handleChange(event)}}>

                    <option>Select Department</option>
                    {department.map((role,index) =>
                        <option  key={index} value={role}>{role}</option>
                    )}
                    </select>

                </form>
            </div>
            <div className="conv_submit">
                <button onClick={(event) => handleSubmit(event)} className='con_submit'>Add</button>
            </div>
        </div>
        </div>
        }
        <ToastContainer />
    </div>
  )*/
