import React from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { useState } from 'react';
import axios from 'axios';

const JSONEditor = ({ json }) => {

    const [jsoo, setJsoo] = useState({});

    const handleJsonChange = (updatedJson) => {
        console.log('Updated JSON:', updatedJson);
        setJsoo(updatedJson)
      };

    const handleupdate = (e) => {
        console.log(jsoo)
        const url = "https://bot.assisto.tech/editconfig"
        const client_id = localStorage.getItem("client_id")
        const goal = Object.keys(jsoo)[0]
        console.log(goal)
        const lang = jsoo[goal].language 
        const final = {
            "client_id" : client_id,
            "lang" : lang,
            "config" : jsoo
        }
        callapi(url,final)

    }

    const callapi = async (url,data) => {
        try{
            const response = await axios.post(url,data)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    };

  return (
    <div className="all_user_container">
    <div container-fluid >
    <div>
      <Editor
        value={json}
        mode="tree"
        modes={['code', 'tree']}
        onChangeText={null}
        onError={null}
        onChange={handleJsonChange}
      />
     </div>
     <div className="add_slot_button">
     <button onClick={(e) => {handleupdate(e)}} >
       Update
     </button>
   </div>
   </div>
   </div>
  );
};

export default JSONEditor;