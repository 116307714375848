import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("main_token")
        navigate("/login")

    })
  return (
    <div>Logout</div>
  )
}
