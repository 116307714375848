import React from "react";
import { useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import GoalDataShow from "../components/GoalDataShow";
import JSONEditor from "../components/JsonShow";

export default function EditGoal() {
  const navigate = useNavigate();

  const [step, setstep] = useState("");
  const [languages, setlanguages] = useState([]);
  const [all_goals, setall_goals] = useState([]);
  const [goal_data, setgoal_data] = useState({});
  const [selectedlang, setselectedlang] = useState("");
  const [selectedgoal, setselectedgoal] = useState("");

  const [loader, setloader] = useState(false);
  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const getAllLang = async () => {
    const url = "https://bot.assisto.tech/supported_langs";

    try {
      const response = await axios.get(url);
      setlanguages(response.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
  };

  const getAllGoals = async () => {
    const url = "https://bot.assisto.tech/get_all_goals";

    try {
      const dat = {
        lang: selectedlang,
        client_id: localStorage.getItem("client_id"),
      };

      const response = await axios.post(url, dat);
      setall_goals(response.data.goals);
      setloader(false);
      console.log(all_goals);
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
  };
  const getGoalData = async () => {
    const url = "https://bot.assisto.tech/getgoal";

    try {
      const dat = {
        lang: selectedlang,
        client_id: localStorage.getItem("client_id"),
        goal_name: selectedgoal,
      };

      const response = await axios.post(url, dat);
      setgoal_data(response.data);
      setloader(false);
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
  };


  const UpdateApi = async ()=>{
    console.log("update api called")
    const url = "https://bot.assisto.tech/editconfig";

    try {
      const updatedData = {
        lang: selectedlang,
        client_id: localStorage.getItem("client_id"),
        config:{
          // ...goal_data                                             pending
        }
        
      };
      const response = await axios.post(url, updatedData);
      console.log("Update Response", response.data);
      toast.success("Goal Updated Successfully", toastOptions);
      
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
  }

  const DeleteApi = async () => {
    console.log("delete api called");
    const url = "https://bot.assisto.tech/deletegoal";

    try {
      const deldata = {
        lang: selectedlang,
        client_id: localStorage.getItem("client_id"),
        goal_name: selectedgoal,
      };
      const response = await axios.post(url, deldata);
      console.log("response message", response.data);
      toast.success("Goal Deleted Successfully", toastOptions);
      setstep("");
      setlanguages([]);
      setall_goals([]);
      setgoal_data({});
      setgoal_data([]);
      setselectedgoal("");
      setselectedlang("");
    } catch (error) {
      console.log(error);
      toast.error("Sorry Something went wrong", toastOptions);
    }
  };

  const handleSelectedLang = (event) => {
    setselectedlang(event.target.value);
  };

  const handleSelectedGoal = (event) => {
    setselectedgoal(event.target.value);
  };

  const handleStepallgoal = (event) => {
    
      setloader(true);
      getAllGoals();
      setstep("allgoals");
      
  };

  const handleStepGoalData = (event) => {
    if (selectedgoal === "") {
      toast.error("Please Select the Goal", toastOptions);
    } else {
      setloader(true);
      getGoalData();
      setstep("goaldata");
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("main_token")) {
      navigate("/login");
    } else {
      getAllLang();
      getAllGoals();
    }

    getAllLang();
  }, [loader]);

  return (
    <div className="all_user_container">
      {loader ? (
        <div className="container-fluid">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="goal">
            <div className="goal_header">
              {/* <h3>Edit Goal</h3> */}
              {console.log("goal_data", goal_data)}
            </div>
            {step === "goaldata" ? (
              // <GoalDataShow goal={goal_data} delete_api={DeleteApi} update_api={UpdateApi} />
              <JSONEditor json={goal_data}/>
              
            ) : 
            
            step === "" ? (
              <div className="goal_body">
                <form className="conversation_form"  onChange={handleStepallgoal} >
                  <select
                    className="item_select_lang"
                    id="User-Type"
                    name="language"
                    onChange={(event) => handleSelectedLang(event)}
                  >
                    <option> Select Language</option>
                    {languages.map((lang, index) => {
                      return (
                        <option key={index} value={lang}>
                          {lang}
                        </option>
                      );
                    })}
                  </select>
                </form>
                <div className="add_slot_button">
                  {/* <button onClick={(event) => handleStepallgoal(event)}>
                    Continue
                  </button> */}
                </div>
              </div>
            ) : (
              <div className="goal_body">
                <form className="conversation_form">
                  <select
                    className="item_select_lang"
                    id="User-Type"
                    name="language"
                    onChange={(event) => handleSelectedGoal(event)}
                  >
                    <option>Select Goal</option>
                    {all_goals.map((lang, index) => {
                      return (
                        <option key={index} value={lang}>
                          {lang}
                        </option>
                      );
                    })}
                  </select>
                </form>
                <div className="add_slot_button">
                  <button onClick={(event) => handleStepGoalData(event)}>
                    Continue
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
