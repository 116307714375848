import React from 'react';
import {AiFillHome } from "react-icons/ai";
import { IoPeopleSharp } from "react-icons/io5";
import { AiFillSetting } from "react-icons/ai";
import { HiChatBubbleBottomCenter } from "react-icons/hi2";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { IoIosNotifications } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import {TiFlowSwitch} from "react-icons/ti"


export const SidebarData = [
    {
        title : "Dashboard",
        icon : <AiFillHome/>,
        link : "/Dashboard"
    },
    {
        title : "Users",
        icon : <IoPeopleSharp />,
        link : "/Users"
    },{
        title : "Manage Bot",
        icon : <AiFillSetting />,
        link : "/Manage-bot"
    },{
        title : "Configure Bot",
        icon : <HiChatBubbleBottomCenter /> ,
        link : "/Configure-bot"
    },{
        title : "Roles and Permissions" ,
        icon : <BsFillPersonLinesFill />,
        link : "/Role-Permission"
    },{
        title : "Pipeline",
        icon : <TiFlowSwitch />,
        link :  "/pipeline"
    },
    
    {
        title : "Notifications",
        icon : <IoIosNotifications />,
        link : "/Notifications"
    },{
        title : "Logout",
        icon : <IoLogOut/>,
        link : "/Logout"
    }
]
 
export const SidebarOptions = {
    "Users" : [
        {
            title : "All User",
            link : "/users"

        },
        {
            title : "Add User",
            link : "/add-user"
        }, 
    ],
    "Manage Bot" : [
        {
            title : "Edit Goals",
            link : "/edit_goal"
        },
        {
            title : "Configure Chat Box",
            link : "/configure-chat-box"
        },
        {
            title : "Retrain the bot",
            link : "/retrain_bot"
        },
        {
            title : "Drag & Drop",
            link : "/drag-drop"
        },
        {
            title : "Test Bot",
            link : "/test-bot"
        },
        {
            title : "Simple Bot",
            link : "/simple-app"
        },
        {
            title : "Edit Tables",
            link : "/edit-tables"
        }
    ],
    "Configure Bot" : [
        {
            title : "Add Intents",
            link : "/add_intent"
        },
        {
            title : "Add Utterences",
            link : "/add_utterence"
        },
        {
            title : "Add Department",
            link : "/add_department"
        },
        {
            title : "Configure Bot",
            link : "/configure-bot"
        },
        {
            title : "FAQ",
            link : "/faq"
        },
        {
            title : "Greetings",
            link : "/greetings"
        },
        {
            title : "Conversation Bot",
            link : "/conversation_bot"
        }
    ],
    "Roles and Permissions" : [
        {
            title : "Add Role",
            link : "/add_role"
        },
        {
            title : "Add Permission to Role",
            link : "/add_permission_to_role"
        }
    ]

}