import React, { useEffect, useState } from "react";
import axios from "axios";

function MaskModelling() {
  const [apiModelData, setApiModelData] = useState("");
  const [apiLanguageData, setApiLanguageData] = useState("");
  const [language, setLanguage] = useState("");
  const [model_type, setModelType] = useState("albert");
  const [train_file, setTrainFile] = useState("");
  const [dev_file, setDevFile] = useState("");

  function ValidateSingleInput(oInput) {
    //if (oInput.type == "file") {
    const _validFileExtensions = [".txt"];
    const sFileName = oInput;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        alert(
          "Sorry, " +
            sFileName +
            " is invalid, allowed extensions are: " +
            _validFileExtensions.join(", ")
        );
        //oInput.value = "";
        return false;
      }
      //}
    }
    return true;
  }
  useEffect(() => {
    async function getModelTypesApi() {
      axios
        .get(
          "https://bot.assisto.tech/dashboard_language_integration/model_type"
        )
        .then((response) => {
          setApiModelData(response.data.model_types);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
    getModelTypesApi();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    ValidateSingleInput(train_file);
    ValidateSingleInput(dev_file);
    const headers = "";
    const formData = new FormData();
    formData.append("language", { language });
    formData.append("config_option", { model_type });
    formData.append("train_file", { train_file });
    formData.append("dev_file", { dev_file });
    axios
      .get(
        "https://bot.assisto.tech/dashboard_language_integration/train_mask_mlm",
        { formData }
      )
      .then((response) => {
        console.log(response);
        setApiLanguageData(response);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };
  return (
    <div className="container">
      <h1>Mask Language Modelling</h1>
      <form onSubmit={handleSubmit} className="conversation_form">
        <label className="form-label">
          Language
          <input
            id="language"
            type="text"
            className="form-control"
            placeholder="Language"
            aria-label="default input example"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          />
        </label>
        <label className="form-label">
          Config Option:
          <select
            id="config"
            type="select"
            value={model_type}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
            onChange={(e) => setModelType(e.target.value)}
          >
            {apiModelData &&
              apiModelData.map((apiDetails, index) => {
                return (
                  <option key={index} value={apiDetails}>
                    {apiDetails}
                  </option>
                );
              })}
          </select>
        </label>
        <label className="form-label">
          Training File:
          <input
            id="train_file"
            type="file"
            className="form-control"
            value={train_file}
            onChange={(e) => {
              setTrainFile(e.target.value);
            }}
          />
        </label>
        <label className="form-label">
          Development File:
          <input
            id="dev_file"
            type="file"
            className="form-control"
            value={dev_file}
            onChange={(e) => {
              setDevFile(e.target.value);
            }}
          />
        </label>
        <input type="submit" className="btn btn-primary" />
      </form>
      <div className="container">
        <h1>Response</h1>
        {apiLanguageData}
      </div>
    </div>
  );
}

export default MaskModelling;
